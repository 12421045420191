<template>
    <div v-if="showDialog === false && buttonInsideDialog === false" class="d-flex align-center">
        <v-btn v-if="isInsideDialog === false" class="mr-1" :icon="icon" :disabled="disabled" color="error" @click="deleteItem()" :loading="deleting" depressed>
            <v-icon small class="mr-1">delete</v-icon>
            {{ buttonName }}
        </v-btn>
        <v-btn v-else-if="isInsideDialog === true" text :disabled="disabled" color="error" @click="deleteItem()" :loading="deleting">{{ buttonName }}</v-btn>
        <v-btn v-if="!icon && isInsideDialog === false" color="secondary" outlined exact text :to="cancelRoute" :disabled="deleting">cancel</v-btn>
    </div>
    <v-btn
        v-else-if="showDialog === false && buttonInsideDialog === true"
        color="error"
        :icon="icon"
        :disabled="disabled"
        @click="deleteItem()"
        :loading="deleting"
        text
    >
        {{ buttonName }}
    </v-btn>
    <v-dialog v-else v-model="deleteDialog" max-width="500">
        <template v-slot:activator="{ on }">
            <v-btn class="mr-1" :icon="icon" :disabled="disabled" color="primary" text :loading="deleting" v-on="on">
                <v-icon class="mr-1" small>delete</v-icon>
                {{ buttonName }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-icon class="mr-1">delete</v-icon>
                {{ buttonName }}
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>{{ warningText }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mr-1" text @click="deleteDialog = false">Cancel</v-btn>
                <v-btn text color="error" @click="deleteItem()">Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray, isMasterInstance } from '@/utils'

export default {
    name: 'DeleteButton',
    props: {
        buttonName: String,
        apiURL: String,
        id: Number,
        higherLevelId: Number,
        warningText: String,
        getterString: String,
        routeAfter: { type: Object, default: null },
        cancelRoute: { type: Object, default: null },
        icon: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        finallyConditionRouter: { type: Boolean, default: false },
        showDialog: { type: Boolean, default: false },
        fetchString: { type: String, default: '' },
        buttonInsideDialog: { type: Boolean, default: false },
        isInsideDialog: { type: Boolean, default: false },
        objectType: String
    },
    data() {
        return {
            deleting: false,
            deleteDialog: false,
            error: false
        }
    },
    methods: {
        deleteItem: function() {
            this.$emit('error', { error: false })
            this.$emit('deleting', { value: true })
            this.$data.deleteDialog = false
            this.$data.deleting = true
            this.$data.error = false
            this.$axios
                .delete(this.$props.apiURL, { timeout: 300000 })
                .then(response => {
                    if (this.$props.objectType === 'application' && this.stageAppsIds.includes(this.$props.id)) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'application',
                            newItems: [{ aid: this.$props.id }],
                            updateMode: 'remove'
                        })
                    }
                    if (this.$props.objectType === 'application') {
                        this.$store.dispatch('appStore/fetchRecentApps', this.$route.params.oid)
                    }
                    if (this.$props.objectType === 'snapshot') {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                    } else if (this.$props.objectType === 'instance' && this.$route.params.iid.toString() === this.$props.id.toString()) {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                        this.$store.dispatch('instanceStore/resetInstanceState')
                    } else if (this.$props.objectType === 'space') {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                        this.$store.dispatch('instanceStore/resetInstanceState')
                        this.$store.dispatch('spaceStore/resetSpaceState')
                    }
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.$store.getters[this.$props.getterString](this.$props.id).toUpperCase() + ' successfully removed!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$data.error = true
                    this.$emit('error', { error: true })
                })
                .finally(() => {
                    if (
                        this.$props.objectType === 'snapshot' &&
                        this.$data.error === false &&
                        this.isDistributedInstance &&
                        this.instanceSnapshots.length === 2
                    ) {
                        this.$store.dispatch(this.$props.fetchString, this.$props.higherLevelId).finally(() => {
                            this.$router.push({
                                name: 'snapshot-overview',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.masterInstanceData.iid,
                                    snid: this.masterInstanceData.snid
                                }
                            })
                        })
                    } else if (
                        this.$props.objectType === 'snapshot' &&
                        this.$data.error === false &&
                        this.isDistributedInstance &&
                        this.instanceSnapshots.length > 2 &&
                        this.$route.params.snid.toString() === this.$props.id.toString()
                    ) {
                        this.$store.dispatch(this.$props.fetchString, this.$props.higherLevelId).finally(() => {
                            this.$router.push({
                                name: 'snapshot-overview',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.$route.params.iid,
                                    snid: this.mostRecentDistributedSnapshot.snid
                                }
                            })
                        })
                    } else if (this.$data.error === false) {
                        this.$store.dispatch(this.$props.fetchString, this.$props.higherLevelId).finally(() => {
                            if (this.$props.routeAfter) {
                                this.$router.push(this.$props.routeAfter)
                            }
                        })
                    }
                    this.$emit('deleting', { value: false })
                    this.$data.deleting = false
                    this.$data.deleteDialog = false
                })
        }
    },
    computed: {
        stageAppsIds() {
            return this.stagingObjects.applications.map(app => app.aid)
        },
        masterInstanceData() {
            if (this.$store.state.orgStore.spacesWithPrimarySnapshots) {
                const master = this.$store.state.orgStore.spacesWithPrimarySnapshots.filter(
                    space => isMasterInstance(space.instance_long_id) === true && space.sid.toString() === this.$route.params.sid.toString()
                )
                return master[0]
            }
            return null
        },
        mostRecentDistributedSnapshot() {
            if (this.isDistributedInstance && this.instanceSnapshots) {
                const sortedSnapshots = sortArray(this.instanceSnapshots, 'iid', 'descending', false)
                const nonDevelopmentSnapshots = sortedSnapshots.filter(
                    snapshot => snapshot.long_id !== 'Current State' && snapshot.snid.toString() !== this.$props.id.toString()
                )
                if (nonDevelopmentSnapshots.length) {
                    return nonDevelopmentSnapshots[0]
                }
            }
            return null
        },
        ...mapState('snapshotStore', ['stagingObjects', 'applications']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapGetters('instanceStore', ['isDistributedInstance'])
    }
}
</script>
